import { render, staticRenderFns } from "./historyKoder.vue?vue&type=template&id=733b21e6&scoped=true&"
import script from "./historyKoder.vue?vue&type=script&lang=js&"
export * from "./historyKoder.vue?vue&type=script&lang=js&"
import style0 from "./historyKoder.vue?vue&type=style&index=0&id=733b21e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733b21e6",
  null
  
)

export default component.exports